// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
    background-color: #f8f9fa; /* Light grey background */
    border-bottom: 2px solid #0079aa; /* Blue border at the bottom */
    padding: 10px 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .navbar-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .navbar-item {
    margin: 0 15px;
  }
  
  .navbar-link {
    color: #0054a4;
    text-decoration: none;
    font-weight: bold;
    font-size: 18px;
    transition: color 0.3s ease;
  }
  
  .navbar-link:hover {
    color: #0079aa;
  }
  
  .navbar-hospital {
    color: #0054a4;
    font-weight: normal;
    font-size: 16px;
  }
  
  .navbar-button {
    background-color: #0079aa; /* Blue button */
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .navbar-button:hover {
    background-color: #0054a4; /* Darker blue on hover */
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Navbar.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB,EAAE,0BAA0B;IACrD,gCAAgC,EAAE,8BAA8B;IAChE,kBAAkB;IAClB,wCAAwC;EAC1C;;EAEA;IACE,qBAAqB;IACrB,SAAS;IACT,UAAU;IACV,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;EACrB;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,cAAc;IACd,qBAAqB;IACrB,iBAAiB;IACjB,eAAe;IACf,2BAA2B;EAC7B;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,cAAc;IACd,mBAAmB;IACnB,eAAe;EACjB;;EAEA;IACE,yBAAyB,EAAE,gBAAgB;IAC3C,YAAY;IACZ,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;IACf,sCAAsC;EACxC;;EAEA;IACE,yBAAyB,EAAE,yBAAyB;EACtD","sourcesContent":[".navbar {\n    background-color: #f8f9fa; /* Light grey background */\n    border-bottom: 2px solid #0079aa; /* Blue border at the bottom */\n    padding: 10px 20px;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n  }\n  \n  .navbar-list {\n    list-style-type: none;\n    margin: 0;\n    padding: 0;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n  }\n  \n  .navbar-item {\n    margin: 0 15px;\n  }\n  \n  .navbar-link {\n    color: #0054a4;\n    text-decoration: none;\n    font-weight: bold;\n    font-size: 18px;\n    transition: color 0.3s ease;\n  }\n  \n  .navbar-link:hover {\n    color: #0079aa;\n  }\n  \n  .navbar-hospital {\n    color: #0054a4;\n    font-weight: normal;\n    font-size: 16px;\n  }\n  \n  .navbar-button {\n    background-color: #0079aa; /* Blue button */\n    color: white;\n    border: none;\n    padding: 8px 16px;\n    border-radius: 4px;\n    cursor: pointer;\n    transition: background-color 0.3s ease;\n  }\n  \n  .navbar-button:hover {\n    background-color: #0054a4; /* Darker blue on hover */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
