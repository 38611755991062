import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Navbar.css'; // Import the CSS file for the Navbar

function Navbar() {
  const token = localStorage.getItem('token');
  const hospital = localStorage.getItem('hospital');
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('hospital');
    navigate('/');
  };

  return (
    <nav className="navbar">
      <ul className="navbar-list">
        <li className="navbar-item">
          <Link to="/" className="navbar-link">NICUCALC</Link>
        </li>
        {token ? (
          <>
            <li className="navbar-item">
              <Link to="/admin" className="navbar-link">Admin Page</Link>
            </li>
            <li className="navbar-item">
              <span className="navbar-hospital">Hospital: {hospital}</span>
            </li>
            <li className="navbar-item">
              <button onClick={handleLogout} className="navbar-button">Logout</button>
            </li>
          </>
        ) : (
          <li className="navbar-item">
            <Link to="/login" className="navbar-link">Login</Link>
          </li>
        )}
      </ul>
    </nav>
  );
}

export default Navbar;
