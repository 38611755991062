// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-dropdown {
    position: relative;
    width: 205px;
  }
  
  .input-container {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    position: relative;
    top: -7px; /* Move the button up by 3 pixels */
    width: 195px;
  }
  
  .input-container input {
    border: none;
    padding: 10px;
    flex: 1 1;
  }
  
  .input-container button {
    border: none;
    background: transparent;
    cursor: pointer;
    padding: 8px;

    
  }
  
  .dropdown-menu {
    position: absolute;
    bottom: 45px;
    left: 0;
    right: 0;
    width: 195px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background: white;
    z-index: 1000;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .dropdown-menu li {
    padding: 8px;
    cursor: pointer;
  }
  
  .dropdown-menu li:hover {
    background-color: #f0f0f0;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/CustomDropdown.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;EACd;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,kBAAkB;IAClB,kBAAkB;IAClB,SAAS,EAAE,mCAAmC;IAC9C,YAAY;EACd;;EAEA;IACE,YAAY;IACZ,aAAa;IACb,SAAO;EACT;;EAEA;IACE,YAAY;IACZ,uBAAuB;IACvB,eAAe;IACf,YAAY;;;EAGd;;EAEA;IACE,kBAAkB;IAClB,YAAY;IACZ,OAAO;IACP,QAAQ;IACR,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;IAClB,iBAAiB;IACjB,aAAa;IACb,gBAAgB;IAChB,UAAU;IACV,SAAS;EACX;;EAEA;IACE,YAAY;IACZ,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".custom-dropdown {\n    position: relative;\n    width: 205px;\n  }\n  \n  .input-container {\n    display: flex;\n    align-items: center;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    position: relative;\n    top: -7px; /* Move the button up by 3 pixels */\n    width: 195px;\n  }\n  \n  .input-container input {\n    border: none;\n    padding: 10px;\n    flex: 1;\n  }\n  \n  .input-container button {\n    border: none;\n    background: transparent;\n    cursor: pointer;\n    padding: 8px;\n\n    \n  }\n  \n  .dropdown-menu {\n    position: absolute;\n    bottom: 45px;\n    left: 0;\n    right: 0;\n    width: 195px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    background: white;\n    z-index: 1000;\n    list-style: none;\n    padding: 0;\n    margin: 0;\n  }\n  \n  .dropdown-menu li {\n    padding: 8px;\n    cursor: pointer;\n  }\n  \n  .dropdown-menu li:hover {\n    background-color: #f0f0f0;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
