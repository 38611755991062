import React, { useState } from 'react';
import './CustomDropdown.css';

const CustomDropdown = ({ name, value, onChange }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const options = [
    { display: 'No Date', value: 'No Date' },
    { display: 'DOL', value: '_ DOL' },
    { display: 'PMA', value: '__w _d PMA' },
    { display: "2 PMA's", value: '__w _d, __w _d PMA' },
  ];

  const handleInputChange = (e) => {
    onChange(e);
  };

  const handleOptionClick = (option) => {
    onChange({ target: { name, value: option } });
    setIsDropdownOpen(false);
  };

  return (
    <div className="custom-dropdown">
      <div className="input-container">
        <input
          type="text"
          name={name}
          value={value}
          onChange={handleInputChange}
          placeholder="Date to be calculated"
        />
        <button type="dropButton" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
          ▼
        </button>
      </div>
      {isDropdownOpen && (
        <ul className="dropdown-menu">
          {options.map((option, index) => (
            <li key={index} onClick={() => handleOptionClick(option.value)}>
              {option.display}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CustomDropdown;
