const ToggleSwitch = ({ enableROP, handleToggleROP }) => {
    return (
      <div className="input-group">
        <label htmlFor="enable-rop">Enable ROP:</label>
        <label className="switch">
          <input
            id="enable-rop"
            type="checkbox"
            checked={enableROP}
            onChange={handleToggleROP}
          />
          <span className="slider"></span>
        </label>
      </div>
    );
  };
export default ToggleSwitch;