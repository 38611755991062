import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './LoginPage.css'

function LoginPage() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const[errorMessage, setErrorMessage] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post('https://backendnicunetwork-5616d29ec9f7.herokuapp.com/api/auth/login', { username, password });
      const token = res.data.token;
      const hospital = res.data.hospital;

      localStorage.setItem('token', token);
      localStorage.setItem('hospital', hospital);

      navigate('/admin'); // Redirect to the Admin Page after login
    } catch (err) {
      setErrorMessage(err.response?.data?.message || err.message)
      console.error('Login failed:', err.response?.data?.message || err.message);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div className="login-container">
      <form className="login-form" onSubmit={handleLogin}>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Username"
          required
        />
        <div className="password-container">
          <input
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            required
          />
          <div className="checkbox-wrapper">
      <div className="checkbox-container">
        <input
          type="checkbox"
          id="show-password"
          checked={showPassword}
          onChange={togglePasswordVisibility}
        />
        <label htmlFor="show-password">Show Password</label>
      </div>
    </div>
        </div>
        <button type="submit">Login</button>
        <div className='error-message'>
          {errorMessage}
        </div>
      </form>
    </div>
  
  );
}

export default LoginPage;
