import React, { useState, useEffect } from 'react';
import axios from 'axios';
import dataParser from './DataParser';
import DOMPurify from 'dompurify';
import ExpandablePanel from '../components/ExpandablePanel';
import './MainPage.css'
import CopyButton from '../components/CopyButton';
import ToggleSwitch from '../components/ToggleSwitch';

function MainPage() {
  const [guidelines, setGuidelines] = useState([]);
  const [selectedHospital, setSelectedHospital] = useState(localStorage.getItem('hospital') || 'CookChildrens');
  const [inputGestAgeWeeks, setInputGestAgeWeeks] = useState('');
  const [inputGestAgeDays, setInputGestAgeDays] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [birthWeight, setBirthWeight] = useState('');
  const [output, setOutput] = useState([]);
  const [showCopyButton, setShowCopyButton] = useState(false);
  const [enableROP, setEnableROP] = useState(true);


  let hospital = localStorage.getItem('hospital'); // Get the hospital name from localStorage
  const token = localStorage.getItem('token');
  const gestAgeDays =
    (inputGestAgeWeeks ? parseInt(inputGestAgeWeeks, 10) * 7 : 0) +
    (inputGestAgeDays ? parseInt(inputGestAgeDays, 10) : 0);


  // Chooses hospital when not signed in
  if (!token) {
    hospital = selectedHospital;
  }

  // Gets corresponding hospital when signed in
  useEffect(() => {
    let hospital = localStorage.getItem('hospital') || selectedHospital;
  
    if (!token) {
      hospital = selectedHospital;
    }
  
    const fetchGuidelines = async () => {
      try {
        const res = await axios.get(`https://backendnicunetwork-5616d29ec9f7.herokuapp.com/api/guideline/${hospital}`);
        setGuidelines(res.data);
      } catch (err) {
        console.error('Failed to fetch guidelines:', err.response?.data?.message || err.message);
      }
    };
  
    fetchGuidelines();
  
    if (hospital) {
      localStorage.setItem('hospital', hospital);
    }
  }, [selectedHospital, token]);
  

  // Allows non-admins to change hospital
  const handleHospitalChange = (e) => {
    const selected = e.target.value;
    setSelectedHospital(selected);
  };

const treatmentList = dataParser(guidelines, birthWeight, gestAgeDays, dateOfBirth, enableROP);

console.log(treatmentList)

const getDateClass = (date) => {
  if (!date || isNaN(Date.parse(date))) {
    return 'invalid-date';
  }

  // Convert both dates to the same locale-specific date string format
  const currentDateStr = new Date().toLocaleDateString('en-US', );
  const comparisonDateStr = date.toLocaleDateString('en-US', );

  console.log("Current Date:", currentDateStr, "Comparison Date:", comparisonDateStr);

  if (currentDateStr === comparisonDateStr) {
    return 'current-date';
  } else if (new Date(comparisonDateStr) < new Date(currentDateStr)) {
    return 'past-date';
  } else {
    return 'future-date';
  }
};

const getOutputText = () => {
  const stripHtmlTags = (html) => {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
  };

  return output.map(item => {
    const text = item.description;
    return stripHtmlTags(text);
  }).join('\n');
};

const handleSubmit = (e) => {
  e.preventDefault();
  setShowCopyButton(true);
  setOutput(treatmentList);
};

const handleToggleROP = () => {
  setEnableROP(!enableROP);
};


return (
  <div>
    <div className="NeoTool">
      <form onSubmit={handleSubmit}>
        <h1>NICU Patient Treatment Generator</h1>
        {!token ? (
          <>
            <h3>Select a Hospital</h3>
            <select className="Hospital-Select" value={selectedHospital} onChange={handleHospitalChange}>
              <option value="hospital1">Testing Hospital</option>
              <option value="CookChildrens">Pediatrix FW</option>
            </select>
          </>
        ) : (
          <h2>Hospital: {hospital}</h2>
        )}
        <div className="input-group">
          <label htmlFor="gestational-age-weeks">Birth Gestational Age:</label>
          <input
            className="weeks-input"
            id="gestational-age-weeks"
            type="text"
            value={inputGestAgeWeeks}
            onChange={(e) => setInputGestAgeWeeks(e.target.value)}
            placeholder="Weeks"
          />
          <label className="label-inline" htmlFor="gestational-age-days">w</label>
          <input
            className="days-input"
            id="gestational-age-days"
            type="text"
            value={inputGestAgeDays}
            onChange={(e) => setInputGestAgeDays(e.target.value)}
            placeholder="Days"
          />
          <label className="label-inline">d</label>
        </div>

        <div className="input-group">
          <label htmlFor="dob">DOB:</label>
          <input
            id="dob"
            type="date"
            value={dateOfBirth}
            onChange={(e) => setDateOfBirth(e.target.value)}
          />
        </div>

        <div className="input-group">
          <label htmlFor="birth-weight">Birth weight:</label>
          <input
            className="weight-input"
            id="birth-weight"
            type="text"
            value={birthWeight}
            onChange={(e) => setBirthWeight(e.target.value)}
            placeholder="Grams"
          />
          <label className="label-inline">g</label>
        </div>

        <div className="button-container">
          <button type="submit" className="generate-button">
            Generate Treatment Dates
          </button>
          <CopyButton getText={getOutputText} show={showCopyButton} />
        </div>
      </form>

      <div className="output-section">
        {output.map((item, index) => {
          const dateClass = getDateClass(item.date || '');
          const sanitizedDescription = DOMPurify.sanitize(item.description || '');

          return (
            <div key={index} className={`${dateClass} item-container`}>
              <div
                dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
                className="item-description"
              />
              {item.source && (
                <a href={item.source} target="_blank" rel="noopener noreferrer" className="item-source">
                  ⓘ
                </a>
              )}
            </div>
          );
        })}
      </div>

      {token && (
        <ExpandablePanel title={`View guidelines for ${hospital}`}>
          {guidelines.length > 0 ? (
            <ul>
              {guidelines.map((guideline, index) => (
                <li key={index}>
                  <strong>{guideline.guidelineName}</strong>: {guideline.when} - {guideline.date}
                </li>
              ))}
            </ul>
          ) : (
            <p>No guidelines available.</p>
          )}
        </ExpandablePanel>
      )}

      {/* Disclaimer Section */}
      <div className="disclaimer">
        <h3>Disclaimer</h3>
        <p>
          By using this website, you acknowledge the possibility that there may be incomplete or inaccurate information presented. The creator is not responsible for any harm or adverse outcomes resulting from the use of the information provided. This website is intended for reference purposes only and should not replace professional medical judgment or guidance.
        </p>
      </div>

      {/* Footer Section */}
      <footer className="NeoTool-footer">
        <p>Made by Cameron Jobson</p>
        <p>
          Contact info: <br />
          Email: <a href="mailto:cameronajobson@gmail.com">cameronajobson@gmail.com</a> <br />
          Phone: <a href="tel:+18173198996">817-319-8996</a>
        </p>
      </footer>
    </div>
  </div>
);
}

export default MainPage;
