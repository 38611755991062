
export default function dataParser(data, birthWeight, gestAgeDays, dateOfBirth, enableROP = true) {

    // Turns the when string in guidline data into a boolean
    function translateCondition(condition) {
        try {
            
            // Remove all '/' characters
            condition = condition.replace(/\//g, '');
        
            // Remove all whitespace
            condition = condition.replace(/\s+/g, '');

            condition = condition.toLowerCase()
            if (condition === "allpatients"){
                return true
            }
        
            // Handle range expressions like "XXXXg TO YYYYg"
            condition = condition.replace(/(\d+)gTO(\d+)g/gi, (_, minWeight, maxWeight) => {
                return `(birthWeight >= ${minWeight} && birthWeight <= ${maxWeight})`;
            });
    
            // Handle gestational age range expressions like "22w0d TO 24w6d"
            condition = condition.replace(/(\d+)w(\d+)dTO(\d+)w(\d+)d/gi, (_, minWeeks, minDays, maxWeeks, maxDays) => {
                const minGestAgeDays = parseInt(minWeeks) * 7 + parseInt(minDays);
                const maxGestAgeDays = parseInt(maxWeeks) * 7 + parseInt(maxDays);
                return `(gestAgeDays >= ${minGestAgeDays} && gestAgeDays <= ${maxGestAgeDays})`;
            });
        
            // Handle "Xg" conditions with <=, >=, <, >
            condition = condition.replace(/<=(\d+)g/gi, (_, weight) => {
                return `birthWeight <= ${weight}`;
            }).replace(/>=(\d+)g/gi, (_, weight) => {
                return `birthWeight >= ${weight}`;
            }).replace(/<(\d+)g/gi, (_, weight) => {
                return `birthWeight < ${weight}`;
            }).replace(/>(\d+)g/gi, (_, weight) => {
                return `birthWeight > ${weight}`;
            });
        
            // Handle gestational age conditions with <=, >=, <, >
            condition = condition.replace(/<=(\d+)w(\d+)d/gi, (_, weeks, days) => {
                return `gestAgeDays <= (${weeks} * 7 + ${days})`;
            }).replace(/>=(\d+)w(\d+)d/gi, (_, weeks, days) => {
                return `gestAgeDays >= (${weeks} * 7 + ${days})`;
            }).replace(/<(\d+)w(\d+)d/gi, (_, weeks, days) => {
                return `gestAgeDays < (${weeks} * 7 + ${days})`;
            }).replace(/>(\d+)w(\d+)d/gi, (_, weeks, days) => {
                return `gestAgeDays > (${weeks} * 7 + ${days})`;
            }).replace(/<=(\d+)w/gi, (_, weeks) => {
                return `gestAgeDays <= (${weeks} * 7)`;
            }).replace(/>=(\d+)w/gi, (_, weeks) => {
                return `gestAgeDays >= (${weeks} * 7)`;
            }).replace(/<(\d+)w/gi, (_, weeks) => {
                return `gestAgeDays < (${weeks} * 7)`;
            }).replace(/>(\d+)w/gi, (_, weeks) => {
                return `gestAgeDays > (${weeks} * 7)`;
            });
        
            // Replace 'AND' with '&&' and 'OR' with '||'
            condition = condition.replace(/AND/gi, '&&').replace(/OR/gi, '||');
            let variables = { birthWeight: birthWeight, gestAgeDays: gestAgeDays };
            let result = evaluateExpression(condition, variables)
            if (result === 'true'){
                return true;
            } else if (result === 'false'){
                return false;
            }
            throw new Error();
        } catch (error) {
            console.error(`Error evaluating condition for guideline (${condition})`, error);
            return false;
        }
    }

    function evaluateExpression(expr, variables = {}) {
        // Regular expressions for different operators and parentheses
        let parens = /\(([^()]+)\)/;            // Regex for identifying parenthetical expressions
        let exp = /(\d+(?:\.\d+)?) ?\^ ?(\d+(?:\.\d+)?)/; // Regex for exponentials
        let mul = /(\d+(?:\.\d+)?) ?\* ?(\d+(?:\.\d+)?)/; // Regex for multiplication
        let div = /(\d+(?:\.\d+)?) ?\/ ?(\d+(?:\.\d+)?)/; // Regex for division
        let add = /(\d+(?:\.\d+)?) ?\+ ?(\d+(?:\.\d+)?)/; // Regex for addition
        let sub = /(\d+(?:\.\d+)?) ?- ?(\d+(?:\.\d+)?)/;  // Regex for subtraction
        let comp = /(\d+(?:\.\d+)?) ?(>=|<=|>|<|==|!=) ?(\d+(?:\.\d+)?)/; // Comparison operators
        let logic = /(\btrue\b|\bfalse\b) ?(&&|\|\|) ?(\btrue\b|\bfalse\b)/; // Logical operators
    
        // Replace variable names with their values in the expression
        expr = expr.replace(/\b\w+\b/g, function(match) {
            return variables.hasOwnProperty(match) ? variables[match] : match;
        });
    
        // Recursive function to evaluate the expression
        function evaluate(expr) {
            if (isNaN(Number(expr))) {
                if (parens.test(expr)) {
                    let newExpr = expr.replace(parens, function(match, subExpr) {
                        return evaluate(subExpr);  // Recursively evaluate parentheses
                    });
                    return evaluate(newExpr);
                } else if (exp.test(expr)) {
                    let newExpr = expr.replace(exp, function(match, base, pow) {
                        return Math.pow(Number(base), Number(pow));  // Evaluate exponentiation
                    });
                    return evaluate(newExpr);
                } else if (mul.test(expr)) {
                    let newExpr = expr.replace(mul, function(match, a, b) {
                        return Number(a) * Number(b);  // Evaluate multiplication
                    });
                    return evaluate(newExpr);
                } else if (div.test(expr)) {
                    let newExpr = expr.replace(div, function(match, a, b) {
                        if (b !== 0) return Number(a) / Number(b);  // Evaluate division
                        else throw new Error('Division by zero');
                    });
                    return evaluate(newExpr);
                } else if (add.test(expr)) {
                    let newExpr = expr.replace(add, function(match, a, b) {
                        return Number(a) + Number(b);  // Evaluate addition
                    });
                    return evaluate(newExpr);
                } else if (sub.test(expr)) {
                    let newExpr = expr.replace(sub, function(match, a, b) {
                        return Number(a) - Number(b);  // Evaluate subtraction
                    });
                    return evaluate(newExpr);
                } else if (comp.test(expr)) {
                    let newExpr = expr.replace(comp, function(match, left, operator, right) {
                        left = Number(left);
                        right = Number(right);
                        switch (operator) {
                            case '>=': return left >= right;
                            case '<=': return left <= right;
                            case '>': return left > right;
                            case '<': return left < right;
                            case '==': return left === right;
                            case '!=': return left !== right;
                            default: throw new Error(`Unexpected operator: ${operator}`);  // Default case to handle unexpected operators
                        }
                    });
                    return evaluate(newExpr);
                } else if (logic.test(expr)) {
                    let newExpr = expr.replace(logic, function(match, left, operator, right) {
                        left = (left === 'true');
                        right = (right === 'true');
                        return operator === '&&' ? left && right : left || right;  // Evaluate logical operators
                    });
                    return evaluate(newExpr);
                } else {
                    return expr;  // Return final evaluated expression
                }
            }
            return Number(expr);  // Return if the expression is a number
        }
    
        // Start evaluating the full expression
        return evaluate(expr);
      }

    // Turns all instances of 34w 6d into 240 days
    function convertWeeksDaysToDays(input) {
        return input.replace(/(\d+)w\s*(\d*)d?/g, (match, weeks, days) => {
            let totalDays = parseInt(weeks) * 7 + (days ? parseInt(days) : 0);
            return totalDays + "d";
        }).replace(/(\d+)w/g, (match, weeks) => {
            return parseInt(weeks) * 7 + "d";
        });
    }
    
    // Turns the date string in guidline data to readable variables
    function parseDateString(input) {
        input = convertWeeksDaysToDays(input); // Convert Xw Yd to total days before parsing
    
        let result = {
            number: null,
            number2: null,
            type: ''
        };
    
        if (input === "No Date") {
            result.type = "No Date";
        } else if (input.includes("PMA")) {
            result.type = "PMA";
            const numbers = input.match(/\d+/g);
            if (numbers) {
                result.number = parseInt(numbers[0]);
                if (numbers.length > 1) {
                    result.number2 = parseInt(numbers[1]);
                }
            }
        } else if (input.includes("DOL")) {
            result.type = "DOL";
            result.number = parseInt(input.match(/\d+/)[0]);
        }
    
        return result;
    }

    const plainText = (description) => {
        return {
            description: description,
            date: null
        }
    }

    const formatDate = (date) => {
        // Format the date as a local date string without timezone conversion
        const formattedDate = date.toLocaleDateString('en-US', {
          month: '2-digit',
          day: '2-digit',
          timeZone: 'UTC' // Use UTC to avoid timezone offset
        });
      
        // Get the appropriate class based on the date
        const dateClass = getDateClass(date);
      
        // Wrap the formatted date in HTML <strong> tags and apply the class
        return `<strong class="${dateClass}">${formattedDate}</strong>`;
    };

    const calculateTreatmentDate = (dateOfBirth, daysOffset) => {
        const dob = new Date(dateOfBirth);
        dob.setUTCHours(0, 0, 0, 0); // Set time to midnight UTC
        dob.setUTCDate(dob.getUTCDate() + daysOffset);
        return dob;
    };
    
    // Generate past dates in red, current dates in green
    const getDateClass = (date) => {
        if (!date || isNaN(Date.parse(date))) {
        return 'invalid-date';
        }
    
        // Convert both dates to the same locale-specific date string format
        const currentDateStr = new Date().toLocaleDateString('en-US', );
        const comparisonDateStr = date.toLocaleDateString('en-US', { timeZone: 'UTC' });
    
        console.log("Current Date:", currentDateStr, "Comparison Date:", comparisonDateStr);
    
        if (currentDateStr === comparisonDateStr) {
        return 'current-date';
        } else if (new Date(comparisonDateStr) < new Date(currentDateStr)) {
        return 'past-date';
        } else {
        return 'future-date';
        }
    };

    const getPMADate = (description, CGA) => {
        if (!dateOfBirth) return '';
        const dob = new Date(dateOfBirth);
        const treatmentDate = new Date(
          dob.getTime() +
          CGA * 24 * 60 * 60 * 1000 -
          gestAgeDays * 24 * 60 * 60 * 1000
        );
        return {
          description: `${formatDate(treatmentDate)} ${description}`,
          date: treatmentDate
        };
    };

    function toUTC(date){
        let utcDate = new Date(date.getTime());

        // Adjust the UTC date by subtracting the local time zone offset
        utcDate.setMinutes(date.getMinutes() + date.getTimezoneOffset());
        return utcDate;
    }

    // For treatment dates determined by Days Of Life
    const getDOLDate = (description, daysAdd) => {
        if (!dateOfBirth) return '';
        return {
        description: `${formatDate(calculateTreatmentDate(dateOfBirth, daysAdd))} ${description}`,
        date: toUTC(calculateTreatmentDate(dateOfBirth, daysAdd)), 
        };
    };

    //For treatments with 2 dates
    const getTwoPMADate = (description, CGA_days1, CGA_days2) => {
        if (!dateOfBirth) return '';
    
        const dob = new Date(dateOfBirth);
        const treatmentDate1 = new Date(
        dob.getTime() + CGA_days1 * 24 * 60 * 60 * 1000 - (gestAgeDays * 24 * 60 * 60 * 1000)
        );

        const treatmentDate2 = new Date(
        dob.getTime() + CGA_days2 * 24 * 60 * 60 * 1000 - (gestAgeDays * 24 * 60 * 60 * 1000)
        );
    
        return {
        description: `${formatDate(treatmentDate1)} - ${formatDate(treatmentDate2)} ${description}`,
        date: treatmentDate1,
        };
    };

    function sortByDate(arr) {
        return arr.sort((a, b) => {
            if (a.date === null) return 1;
            if (b.date === null) return -1;
            return new Date(a.date) - new Date(b.date);
        });
    }

    const calculateRopExamDate = () => {
        if (gestAgeDays < 0) {
          // Handle negative gestAgeTotalDays, if needed
          return; // or throw an error
        }
    
        let weekNumber = 31;
        if (gestAgeDays >= 196 && gestAgeDays < 203) {
          weekNumber = 32;
        } else if (gestAgeDays >= 203 && gestAgeDays < 210) {
          weekNumber = 33;
        } else if (gestAgeDays >= 210 && gestAgeDays < 217) {
          weekNumber = 34;
        } else if (gestAgeDays >= 217 && gestAgeDays < 224) {
          weekNumber = 35;
        } else if (gestAgeDays >= 224 && gestAgeDays < 231) {
          weekNumber = 36;
        } else if (gestAgeDays >= 231 && gestAgeDays < 238) {
          weekNumber = 37;
        } else if (gestAgeDays >= 238 && gestAgeDays < 245) {
          weekNumber = 38;
        } else if (gestAgeDays >= 245 && gestAgeDays < 252) {
          weekNumber = 39;
        } else if (gestAgeDays >= 252) {
          weekNumber = 40;
        }
        return getPMADate("ROP Exam due near this day", weekNumber * 7);
      }

    const treatmentList = () => {
        let treatments = [];

        if (birthWeight <= 0) treatments.push(plainText('Enter a valid Weight.', false));
        if (gestAgeDays <= 0) treatments.push(plainText('Enter a valid Gestational Age.', false));
        if (dateOfBirth === '') treatments.push(plainText('Enter a valid DOB', false));
        if (!birthWeight <= 0  && !gestAgeDays <= 0 && dateOfBirth !== '') {
            for (let i = 0; i < data.length; i++) {
                if (translateCondition(data[i].when)){
                    const description = data[i].guidelineName
                    const dateAndNumber = parseDateString(data[i].date)
                    const type = dateAndNumber.type
                    const number = dateAndNumber.number
                    const number2 = dateAndNumber.number2
                    if (type === 'No Date'){
                        treatments.push(plainText(description))
                    } else if (type === 'DOL'){
                        treatments.push(getDOLDate(description, number))
                    } else if (type === 'PMA' && number2  == null){
                        treatments.push(getPMADate(description, number))
                    } else if (number2 !=  null){
                        treatments.push(getTwoPMADate(description, number, number2))
                    } else {
                        treatments.push(plainText(`error on guideline number ${i + 1}`))
                    }
                }
            }
            if (enableROP && (birthWeight <= 1500 || gestAgeDays <= 216)) {treatments.push(calculateRopExamDate())}
        }
        return sortByDate(treatments)
    }

    return treatmentList()
}